<template>
  <div class="w-auto">
    <Dropzone
      ref-key="dropzoneRef"
      id="dropzoneRef"
      :options="{
        url: 'https://httpbin.org/post',
        paramName: 'file',
        thumbnailWidth: 150,
        acceptedFiles: 'image/*',
        addRemoveLinks: true,
        uploadMultiple: false,
        maxFiles: 1,
        headers: { 'My-Awesome-Header': 'header value' }
      }"
      class="dropzone mt-6"
    >
      <div class="text-lg font-medium">
        {{ i18n('slider.fileUploaderHint') }}
      </div>
    </Dropzone>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, provide } from 'vue'
import Dropzone from 'dropzone'

export default defineComponent({
  setup() {
    let uploadLoading = ref(false)
    const dropzoneRef = ref()
    const filesToSave = ref([])

    provide('bind[dropzoneRef]', (el) => {
      dropzoneRef.value = el
    })

    const clearDropzone = () => {
      Dropzone.forElement('#dropzoneRef').removeAllFiles(true)
      while (filesToSave.value.length > 0) {
        filesToSave.value.pop()
      }
    }

    const getDropZoneInstance = () => {
      return dropzoneRef.value
    }

    const setupDropZone = () => {
      const elDropzoneRef = getDropZoneInstance()
      elDropzoneRef.dropzone.on('success', (file) => {
        if (filesToSave.value.length < 1) filesToSave.value.push(file)
      })

      elDropzoneRef.dropzone.on('removedfile', (file) => {
        filesToSave.value.pop()
      })

      elDropzoneRef.dropzone.on('complete', () => {
        uploadLoading = false
      })

      elDropzoneRef.dropzone.on('uploadprogress', () => {
        uploadLoading = true
      })
    }
    onMounted(() => {
      setupDropZone()
    })
    return {
      setupDropZone,
      uploadLoading,
      filesToSave,
      getDropZoneInstance,
      clearDropzone
    }
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
})
</script>
