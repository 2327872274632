/* eslint-disable */

import { i18n } from '@/locales/i18n'
import { GenericModel } from '@/shared/model/generic-model'
import IdField from '@/shared/fields/id-field'
import StringField from '@/shared/fields/string-field'
import JsonField from '@/shared/fields/json-field'
import DateTimeField from '@/shared/fields/date-time-field'
// import StringArrayField from '@/shared/fields/string-array-field';
import BooleanField from '@/shared/fields/boolean-field'
// import EnumeratorField from '@/shared/fields/enumerator-field';
// import IntegerField from '@/shared/fields/integer-field';
// import DecimalField from '@/shared/fields/decimal-field';
// import ImagesField from '@/shared/fields/images-field';
// import DateTimeRangeField from '@/shared/fields/date-time-range-field';
// import GenericField from '@/shared/fields/generic-field';

function label(name) {
  return i18n(`notification.fields.${name}`)
}
// function enumeratorLabel(name, value) {
//   return i18n(`notification.enumerators.${name}.${value}`);
// }

const fields = {
  id: new IdField('id', label('id'), { required: true }),
  title: new JsonField('title', label('title'), ['en', 'ar']),
  description: new JsonField('description', label('description'), ['en', 'ar']),
  image: new StringField('image', label('image'), ['name', 'publicUrl'], {
    required: false
  }),
  // imageEn: new JsonField('imageEn', label('image'), ['name', 'publicUrl']),
  // imageAr: new JsonField('imageAr', label('image'), ['name', 'publicUrl']),
  publicUrl: new StringField('publicUrl', label('publicUrl')),
  imageIcon: new JsonField('imageIcon', label('imageIcon'), [
    'name',
    'publicUrl'
  ]),
  type: new StringField('type', label('type'), {
    required: true
  }),
  isActive: new BooleanField('isActive', label('isActive')),
  itemId: new StringField('itemId', label('itemId'), { required: true }),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdBy: new StringField('createdBy', label('createdBy')),
  updatedBy: new StringField('updatedBy', label('updatedBy'))
  // createdAtRange: new DateTimeRangeField('createdAtRange', label('createdAtRange')),
}

export class SidebarModel extends GenericModel {
  static get fields() {
    return fields
  }
}
