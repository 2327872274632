<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">
          <h2 class="text-xl font-medium mr-auto border-b pb-5">
            <app-i18n code="sidebar.edit.title" v-if="isEditing"></app-i18n>
            <app-i18n code="sidebar.new.title" v-else></app-i18n>
          </h2>
          <section class="relative">
            <div
              class="flex justify-center"
              style="width: 100%; height: 80vh"
              v-if="findLoading"
            >
              <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
            </div>
            <form v-if="model">
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('sidebar.fields.title') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input
                    id="title-en"
                    type="text"
                    class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                    v-model="model.title.en"
                    :placeholder="i18n('slider.fields.english')"
                  />
                </div>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2"
                  :class="isRTL ? 'lg:pr-5' : 'lg:pl-5'"
                >
                  <input
                    id="title-ar"
                    type="text"
                    class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                    v-model="model.title.ar"
                    :placeholder="i18n('slider.fields.arabic')"
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('sidebar.fields.type') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <TomSelect v-model="model.type" class="w-full mt-2 sm:mt-0">
                    <option
                      v-for="(option, index) in itemIdOptions"
                      :key="index"
                      :value="option.value"
                    >
                      <app-i18n :code="option.label" />
                    </option>
                  </TomSelect>
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 col-span-12 lg:col-span-2"
                >
                  {{ i18n('sidebar.fields.itemId') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input
                    id="itemId"
                    type="text"
                    class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                    v-model="model.itemId"
                    :placeholder="i18n('sidebar.fields.itemId')"
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('sidebar.fields.isActive') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input
                    type="checkbox"
                    id="switch"
                    v-model="model.isActive"
                  /><label for="switch"></label>
                </div>
              </div>
              <div class="grid mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('sidebar.fields.image') }}
                </h4>
                <div class="lg:col-span-10">
                  <div
                    class="relative max-w-[200px] my-5"
                    v-if="isEditing && model.publicUrl"
                  >
                    <img
                      :src="model.publicUrl"
                      :alt="model.publicUrl"
                      style="width: 100%; height: 150px"
                    />
                    <img
                      src="/images/deleteIcon.png"
                      alt="delete image"
                      class="delete-icon w-12 h-12 absolute bottom-0 left-0 cursor-pointer"
                      @click="model.publicUrl = undefined"
                    />
                  </div>
                  <Uploader type="image/*" :maxSize="5" ref="uploader" v-else />
                </div>
              </div>
              <div class="flex justify-center items-center mt-10">
                <AppButton
                  type="button"
                  class="btn bg-theme-31 text-white cursor-pointer"
                  :class="!saveLoading ? 'w-24' : ''"
                  :disabled="saveLoading || findLoading || uploadLoading"
                  :loading="saveLoading"
                  @click="doSubmit"
                >
                  <strong>{{ i18n('common.save') }}</strong>
                  <template v-slot:loading>
                    <app-i18n code="common.loading"></app-i18n>
                    <LoadingIcon
                      icon="three-dots"
                      color="#FFFFFF"
                      style="margin: 0 4px"
                    />
                  </template>
                </AppButton>
                <button
                  type="button"
                  class="btn bg-theme-32 text-black w-24 ml-3 mr-3 cursor-pointer"
                  :disabled="saveLoading || findLoading || uploadLoading"
                  @click="doCancel"
                >
                  <app-i18n code="common.cancel"></app-i18n>
                </button>
              </div>
            </form>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Message from '@/shared/message/toastify'
import { FormSchema } from '@/shared/form/form-schema'
import { SidebarModel } from '@/store/sidebar/sidebar-model'
import Uploader from './uploader.vue'

const { fields } = SidebarModel
const formSchema = new FormSchema([
  fields.id,
  fields.title,
  fields.type,
  fields.isActive,
  fields.itemId,
  fields.image,
  fields.imageIcon
])

export default {
  props: {
    id: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      model: null,
      rules: formSchema.rules(),
      errorMessage: null,
      itemIdOptions: [
        {
          value: 'staticCollection',
          label: 'slider.fields.staticCollection'
        },
        {
          value: 'staticOccasion',
          label: 'slider.fields.staticOccasion'
        },
        {
          value: 'collection',
          label: 'slider.fields.collection'
        },
        {
          value: 'product',
          label: 'slider.fields.product'
        },
        {
          value: 'blog',
          label: 'slider.fields.blog'
        },
        {
          value: 'article',
          label: 'slider.fields.article'
        },
        {
          value: 'page',
          label: 'slider.fields.page'
        },
        {
          value: 'webview',
          label: 'slider.fields.webview'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'sidebar/form/record',
      findLoading: 'sidebar/form/findLoading',
      saveLoading: 'sidebar/form/saveLoading'
    }),
    isEditing() {
      return !!this.id
    },
    filesToSave() {
      return this.$refs.uploader?.filesToSave || []
    }
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.sidebar')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.sidebar')
    }
  },
  async mounted() {
    if (this.isEditing) {
      await this.doFind(this.id)
    } else {
      this.doNew()
    }
    this.model = formSchema.initialValues(
      this.record
        ? {
            ...this.record,
            image: this.record.imageIcon
          }
        : {}
    )
    if (this?.record?.imageIcon) {
      this.model.publicUrl = this.record.imageIcon.publicUrl
    }
    this.model.type = 'staticCollection'
    this.model.isActive = true
  },
  methods: {
    ...mapActions({
      doNew: 'sidebar/form/doNew',
      doFind: 'sidebar/form/doFind',
      doCreate: 'sidebar/form/doCreate',
      doUpdate: 'sidebar/form/doUpdate'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    isFormValid() {
      if (
        this.model.title.en.length === 0 ||
        this.model.title.ar.length === 0
      ) {
        this.errorMessage = 'slider.edit.errors.emptyFields'
        return false
      }
      if (!this.model.type?.length) {
        this.errorMessage = 'slider.edit.errors.emptyFields'
        return false
      }
      if (!this.model.itemId?.length) {
        this.errorMessage = 'slider.edit.errors.emptyFields'
        return false
      }
      if (this.model.type === 'staticCollection') {
        if (!(this.model.image || this.filesToSave.length !== 0)) {
          this.errorMessage = 'slider.edit.errors.emptyFields'
          return false
        }
      }
      return true
    },
    doCancel() {
      this.$router.push('/sidebar')
    },
    doReset() {
      this.model = formSchema.initialValues(this.record)
    },
    async doSubmit() {
      if (!this.isFormValid()) {
        const ERROR = this.i18n(this.errorMessage)
        return Message.error(ERROR)
      }
      const { id, ...values } = this.model
      if (this.isEditing) {
        await this.doUpdate({
          id,
          values: {
            ...values,
            image: this.filesToSave[0]
          }
        })
      } else {
        await this.doCreate({ ...values, image: this.filesToSave[0] })
      }
    }
  },
  components: { Uploader }
}
</script>
<style scoped lang="scss">
input[type='checkbox'] {
  height: 0;
  width: 0;
  visibility: hidden;
}

label {
  cursor: pointer;
  text-indent: -9999px;
  width: 70px;
  height: 35px;
  background: grey;
  display: block;
  border-radius: 100px;
  position: relative;
}

label:after {
  content: '';
  position: absolute;
  top: 5px;
  left: 5px;
  width: 25px;
  height: 25px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

input:checked + label {
  @apply bg-theme-31;
}

input:checked + label:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}

label:active:after {
  width: 40px;
}
</style>
